import {constants} from '../../constants';

export const setTotalOpenJobsRecord = (totalRecord) => {
    return { type: constants.SET_TOTAL_OPEN_JOBS_RECORD, payload: totalRecord };
};

export const removeFromTotalOpenJobsRecord = (totalRecord) => {
    return { type: constants.UPDATE_TOTAL_OPEN_JOBS_RECORD_AFTER_CLAIM, payload: totalRecord };
};

export const addInToTotalOpenJobsRecord = (totalRecord) => {
    return { type: constants.UPDATE_TOTAL_OPEN_JOB_RECORD_AFTER_ADD, payload: totalRecord };
};
