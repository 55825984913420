import React, { useState, forwardRef, useEffect } from 'react';
import back_icon from "../../../assets/images/arrow-left.svg";
import briefcase from "../../../assets/images/briefcase.svg";
import caret_up from "../../../assets/images/caret-up.svg";
import car_cog from "../../../assets/images/car-cog.svg";
import wrench from "../../../assets/images/wrench.svg";
import caret_circle_down from "../../../assets/images/caret-circle-down.svg";
import caret_circle_up from "../../../assets/images/caret-circle-up.svg";
import pencil_simple_line from "../../../assets/images/pencil-simple-line.svg";
import clock_counter_clockwise from "../../../assets/images/clock-counter-clockwise.svg";
import { jobOverviewAction } from '../../../store/action/jobOverviewAction';
import { Tokens } from '../../../constants/index'
import { useDispatch } from 'react-redux';
import moment from "moment";

const CompletedJobDetail = forwardRef(({ selectedJob, onBackButtonClick }, ref) => {
  const [isJobDetailCollapsed, setIsJobDetailCollapsed] = useState(false);
  const [activeServiceIndex, setActiveServiceIndex] = useState(0);
  const [jobOverview, setJobOverview] = useState({});
  const [technicianJobId, setTechnicianJobId] = useState("");
  const [technicianJobStatus, setTechnicianJobStatus] = useState("");
  const dispatch = useDispatch();

  const handleCollapseJobDetail = () => {
    setIsJobDetailCollapsed((prevCollapsed) => !prevCollapsed);
  };

  const handleToggleService = (index) => {
    setActiveServiceIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(() => {
    const fetchJobOverview = async () => {
      if (selectedJob) {
        const result = await dispatch(jobOverviewAction(selectedJob));
        if (result && !result.error) {
          const technicianId = localStorage.getItem(Tokens.TECHNICIAN_ID);
          const filteredTechnicianJobs = result.technicianJobs.filter(
            (techJob) => techJob.technicianId === technicianId
          );
          setJobOverview({
            ...result,
            technicianJobs: filteredTechnicianJobs,
          });
          if (result.technicianJobs && result.technicianJobs.length > 0) {
            setTechnicianJobId(result.technicianJobs[0].id);
            setTechnicianJobStatus(result.technicianJobs[0].jobStatus);
          }
        }
      }
    };
    fetchJobOverview();
  }, [dispatch, selectedJob]);

  return (
    <div className="job-detail-content" ref={ref}>
      <div className="job-detail-top-header">
        <button className="back-button" onClick={onBackButtonClick}>
          <img src={back_icon} alt="Back Icon" className="back-icon" />
          <h5>Back</h5>
        </button>
        <div className="job-status completed">
          <h6>Completed</h6>
        </div>
      </div>
      <div className="job-detail-box">
        <div className="my-job-detail-header">
          <div className="job-detail-header">
            <h4>Job Details</h4>
            <img src={briefcase} alt="Job Details Icon" />
          </div>
          <div className="collapse-icon" onClick={handleCollapseJobDetail}>
            <img src={caret_up} alt="Collapse Icon" className={isJobDetailCollapsed ? 'collapsed' : ''} />
          </div>
        </div>
        {!isJobDetailCollapsed && (
          <>
            <div className="entity">
              <h5 className="entity-label">Job Card No</h5>
              <h4 className="entity-value">{jobOverview.jobCardNumber}</h4>
            </div>
            <div className="entity">
              <h5 className="entity-label">Job Description</h5>
              <h4 className="entity-value">{jobOverview.description}</h4>
            </div>
            <div className="entity">
              <h5 className="entity-label">Estimated Duration</h5>
              <h4 className="entity-value">{jobOverview.estimatedHours} Hours</h4>
            </div>
            <div className="entity">
              <h5 className="entity-label">Priority</h5>
              <h4 className="entity-value">{jobOverview.priority}</h4>
            </div>
            <div className="entity">
              <h5 className="entity-label">Created Date</h5>
              <h4 className="entity-value">
                {moment(jobOverview.jobCreated).format("DD-MM-YYYY")}
              </h4>
            </div>
            <div className="entity">
              <h5 className="entity-label">Notes</h5>
              <h4 className="entity-value">{jobOverview.notes}</h4>
            </div>
          </>
        )}
      </div>
      <div className="job-detail-box">
        <div className="job-detail-header">
          <h4>Services</h4>
          <img src={car_cog} alt="Services Icon" />
        </div>
        {jobOverview.jobServices && jobOverview.jobServices.length > 0 ? (
          jobOverview.jobServices.map((service, index) => (
            <div key={index} className={`service ${activeServiceIndex === index ? 'active' : ''}`}>
              <div className="service-header">
                <h4 className="service-title">{service.serviceName}</h4>
                <button className="service-toggle" onClick={() => handleToggleService(index)}>
                  <img src={caret_circle_down} alt="down" style={{ display: activeServiceIndex === index ? 'none' : 'inline' }} />
                  <img src={caret_circle_up} alt="up" style={{ display: activeServiceIndex === index ? 'inline' : 'none' }} />
                </button>
              </div>
              {activeServiceIndex === index && (
                <div className="service-checklist">
                  <input type="checkbox" className="service-checkbox" />
                  <span className="service-label">Task 1</span>
                </div>
              )}
            </div>
          ))
        ) : (
          <p>No services available for this job.</p>
        )}
      </div>
      <div className="job-detail-box">
        <div className="job-detail-header">
          <h4>Products Required</h4>
          <img src={wrench} alt="Products Icon" />
        </div>
        {jobOverview && jobOverview.jobProductList && jobOverview.jobProductList.length > 0 ? (
          <ul className="products-list">
            {jobOverview.jobProductList.map((product, index) => (
              <li key={index}><h4>{product.productName}</h4></li>
            ))}
          </ul>
        ) : (
          <p>No products available for this job.</p>
        )}
      </div>
    </div>
  );
});

export default CompletedJobDetail;