import apiConfig from "../../config/apiConfig";
import { apiBaseURL, userActionType, toastType, Tokens } from "../../constants";
import { addToast } from "./toastAction";
import { setLoading } from "./loadingAction";

export const myProfile = () => async (dispatch) => {
    dispatch(setLoading(true));
    const technicianEmail = localStorage.getItem(Tokens.EMAIL);

    try {
        const res = await apiConfig.get(apiBaseURL.TECHNICIAN_ID + technicianEmail);
        const response = await apiConfig.get(apiBaseURL.TECHNICIAN_PROFILE + res.data.id);
        dispatch({
            type: userActionType.TECHNICIAN_PROFILE,
            payload: response.data,
        });
    } catch (error) {
        const errorMessage = error.response?.data?.message || "An error occurred. Please try again.";
        dispatch(addToast({ text: errorMessage, type: toastType.ERROR }));
    } finally {
        dispatch(setLoading(false));
    }
};