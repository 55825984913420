import { combineReducers } from 'redux';
import loginReducers from './authReducer';
import toastReducer from './toastReducer';
import changePasswordReducers from './changePasswordReducers';
import loadingReducer from './loadingReducer';
import updateProfileReducer from './updateProfileReducer';
import dateReducer from './dateReducres';
import saveButtonReducer from "./saveButtonReducer";
import dateFormatReducer from "./dateFormatReducer";
import dashboardReducers from './dashboardReducers';
import jobDashboardReducer from './jobDashboardReducer';
import totalOpenJobRecordReducer from './totalOpenJobRecordReducer';
import myJobDashboardReducer from './myJobDashboardReducer';
import totalMyJobRecordReducer from './totalMyJobRecordReducer';
import MyProfileReducer from './MyProfileReducer';

export default combineReducers( {
    loginUser: loginReducers,
    changePasswords: changePasswordReducers,
    isLoading: loadingReducer,
    userProfile: updateProfileReducer,
    dates: dateReducer,  
    isSaving: saveButtonReducer,   
    dateFormat: dateFormatReducer,
    jobCount: dashboardReducers,
    toasts: toastReducer,
    openJobs: jobDashboardReducer,
    totalOpenJobsRecord: totalOpenJobRecordReducer,
    allMyJobs: myJobDashboardReducer,
    totalMyJobsRecord: totalMyJobRecordReducer,
    profileData: MyProfileReducer
} );
