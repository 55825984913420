import apiConfig from '../../config/apiConfig';
import { apiBaseURL, jobsActionType, toastType, Tokens } from '../../constants';
import { setLoading } from "./loadingAction";
import { addToast } from './toastAction';
import  {setTotalMyJobsRecord} from './totalMyJobRecordAction';
import { setSavingButton } from "./saveButtonAction";
import {getFormattedMessage} from '../../shared/sharedMethod';

export const myActiveJobs = () => async (dispatch) => {
    dispatch(setLoading(true));

    try {
        const technicianId = localStorage.getItem(Tokens.TECHNICIAN_ID);
        const response = await apiConfig.get(apiBaseURL.MY_JOBS + technicianId);
        dispatch({
            type: jobsActionType.MY_JOBS,
            payload: response.data,
        });
        dispatch( setTotalMyJobsRecord(
                response.data.length !== undefined &&
                    response.data.length >= 0
                    ? response.data.length
                    : response.data.length
            )
        );
    } catch (error) {
        const errorMessage = error.response?.data?.message || "An error occurred. Please try again.";
        dispatch(addToast({ text: errorMessage, type: toastType.ERROR }));
    } finally {
        dispatch(setLoading(false));
    }
};

export const updateJobStatus = (jobValue, navigate) => async (dispatch) => {
    dispatch(setSavingButton(true));
    try {
        const response = await apiConfig.put(apiBaseURL.UPDATE_JOB_STATUS, jobValue);
        dispatch({
            type: jobsActionType.UPDATE_JOB_STATUS,
            payload: response.data,
        });
        dispatch(
            addToast({
                text: getFormattedMessage("job.success.status.message"),
            })
        );
        return Promise.resolve(true);
    } catch (error) {
        const errorMessage = error.response?.data?.message || "An error occurred. Please try again.";
        dispatch(addToast({ text: errorMessage, type: toastType.ERROR }));
        return Promise.reject(error);
    } finally {
        dispatch(setSavingButton(false));
    }
};

export const getChecklistByService = async (serviceId) => {
    try {
      const response = await apiConfig.get(apiBaseURL.CHECKLIST_BY_SERVICE + '?serviceId=' + serviceId);
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.errors?.[0] || "An error occurred. Please try again.";
      return { error: errorMessage };
    }
  };