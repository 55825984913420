import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { connect } from 'react-redux';
import { Menu } from "antd";
import karstationLogoWhite from '../../assets/images/karstation-logo-white.svg';
import menu_unfold from '../../assets/images/menu-unfold.svg';
import menu_fold from '../../assets/images/menu-fold.svg';
import dashboard_icon from '../../assets/images/dashboard-icon.svg';
import profile_icon from '../../assets/images/profile-icon.svg';
import signout_icon from '../../assets/images/signout-icon.svg';
import { logoutAction } from '../../store/action/authAction';
import { Tokens } from '../../constants/index'

const Sidebar = (props) => {
  const { logoutAction, collapsed, setCollapsed, toggleSider } = props;
  const token = localStorage.getItem(Tokens.VENDOR);
  const navigate = useNavigate();
  const location = useLocation();

  const handleCollapseClick = () => {
    setCollapsed(!collapsed);
    if (window.innerWidth < 767) {
      toggleSider();
    }
  };

  const getActiveKey = () => {
    if (location.pathname.includes('/app/dashboard')) return 'dashboard';
    if (location.pathname.includes('/app/my-profile')) return 'profile';
    return '';
  };

  const onLogOut = () => {
    logoutAction(token, navigate);
    navigate('/login');
  };

  return (
    <div className="sidebar">
      <div className={`logo-container ${collapsed ? 'collapsed-logo-container' : ''}`}>
        {!collapsed ? <img src={karstationLogoWhite} alt='karstation logo' className='logo' /> : null}
        <div className='collapse-icon' onClick={handleCollapseClick}>
          <img src={collapsed ? menu_unfold : menu_fold} alt='collapse icon' />
        </div>
      </div>
      <div className={`top-menu-container ${collapsed ? 'collapsed-top-menu' : ''}`}>
        <Menu
          theme="light"
          mode="inline"
          selectedKeys={[getActiveKey()]}
          onClick={({ key }) => {
            if (key === "dashboard") {
              navigate('/app/dashboard')
            }
          }}
          items={[
            {
              key: "dashboard",  
              icon: (
                <img
                  src={dashboard_icon}
                  alt="Dashboard Icon"
                  style={{
                    width: collapsed ? '24px' : '24px',
                    transition: 'width 0.3s ease',
                  }}
                />
              ),
              label:"Dashboard",
            },
          ]}
        />
      </div>
      <div className={`bottom-menu-container ${collapsed ? 'collapsed-bottom-menu' : ''}`}>
        <Menu
          theme="light"
          mode="inline"
          selectedKeys={[getActiveKey()]}
          onClick={({ key }) => {
            if (key === "profile") {
              navigate('/app/my-profile')
            }
          }}
          items={[
            {
              key: "profile",
              icon: (
                <img
                  src={profile_icon}
                  alt="Profile Icon"
                />
              ),
              label: 'Profile Settings',
            },
            {
              key: "logout",
              onClick: onLogOut,
              icon: (
                <img
                  src={signout_icon}
                  alt="Signout Icon"
                />
              ),
              label: "Logout",
            },
          ]}
        />
      </div>
    </div>
  );
};

export default connect(null, { logoutAction })(Sidebar);