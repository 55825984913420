import {constants} from '../../constants';

export const setTotalMyJobsRecord = (totalRecord) => {
    return { type: constants.SET_TOTAL_MY_JOBS_RECORD, payload: totalRecord };
};

export const removeFromTotalMyJobsRecord = (totalRecord) => {
    return { type: constants.UPDATE_TOTAL_MY_JOBS_RECORD_AFTER_CLAIM, payload: totalRecord };
};

export const addInToTotalMyJobsRecord = (totalRecord) => {
    return { type: constants.UPDATE_TOTAL_MY_RECORD_AFTER_ADD, payload: totalRecord };
};
