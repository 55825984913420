import apiConfig from '../../config/apiConfig';
import { apiBaseURL, jobsActionType, toastType, Tokens } from '../../constants';
import { setLoading } from "./loadingAction";
import { addToast } from './toastAction';
import { setTotalOpenJobsRecord, removeFromTotalOpenJobsRecord } from './totalOpenJobRecordAction';
import { setSavingButton } from "./saveButtonAction";
import {getFormattedMessage} from '../../shared/sharedMethod';

export const availableOpenJobs = () => async (dispatch) => {
    dispatch(setLoading(true));

    try {
        const response = await apiConfig.get(apiBaseURL.AVAILABLE_JOBS);
        dispatch({
            type: jobsActionType.FETCH_AVAILABLE_JOBS,
            payload: response.data,
        });
        dispatch( setTotalOpenJobsRecord(
                response.data.length !== undefined &&
                    response.data.length >= 0
                    ? response.data.length
                    : response.data.length
            )
        );
    } catch (error) {
        const errorMessage = error.response?.data?.message || "An error occurred. Please try again.";
        dispatch(addToast({ text: errorMessage, type: toastType.ERROR }));
    } finally {
        dispatch(setLoading(false));
    }
};
/*
export const fetchJob1 = (jobId) => async (dispatch) => {
    dispatch(setLoading(true));

    try {
        const response = await apiConfig.get(
            `${apiBaseURL.JOB_OVERVIEW}?jobId=${jobId}`
        );
        dispatch({
            type: jobsActionType.VIEW_JOB,
            payload: response.data,
        });
    } catch (error) {
        const errorMessage = error.response?.data?.message || "An error occurred. Please try again.";
        dispatch(addToast({ text: errorMessage, type: toastType.ERROR }));
    } finally {
        dispatch(setLoading(false));
    }
};

export const fetchJob = (jobId) => async () => {
    try {
        const response = await apiConfig.get(`${apiBaseURL.JOB_OVERVIEW}?jobId=${jobId}`);
        return response.data;
    } catch (error) {
        setLoading(false);
        const errorMessage = error.response?.data?.errors?.[0] || "An error occurred. Please try again.";
        return { error: errorMessage };
    }
};*/

export const claimJob = (jobId, navigate) => async (dispatch) => {
    dispatch(setSavingButton(true));
    const technicianId = localStorage.getItem(Tokens.TECHNICIAN_ID);
    try {
        const response = await apiConfig.post(
            `${apiBaseURL.CLAIM_JOB}?jobId=${jobId}&technicianId=${technicianId}`
        );
        dispatch({
            type: jobsActionType.CLAIM_JOB,
            payload: response.data,
        });
        dispatch(removeFromTotalOpenJobsRecord(1));
        dispatch(
            addToast({
                text: getFormattedMessage("job.success.claim.message"),
            })
        );
        return Promise.resolve(true);
    } catch (error) {
        const errorMessage = error.response?.data?.errors?.[0] || "An error occurred. Please try again.";
        dispatch(addToast({ text: errorMessage, type: toastType.ERROR }));
    } finally {
        dispatch(setSavingButton(false));
    }
};
