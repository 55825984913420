import apiConfig from '../../config/apiConfig';
import {apiBaseURL} from '../../constants';
import {setLoading} from "./loadingAction";

export const jobOverviewAction = (jobId) => async () => {
    try {
        const response = await apiConfig.get(`${apiBaseURL.JOB_OVERVIEW}?jobId=${jobId}`);
        return response.data;
    } catch (error) {
        setLoading(false);
        const errorMessage = error.response?.data?.errors?.[0] || "An error occurred. Please try again.";
        return { error: errorMessage };
    }
};
