import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import briefcase_icon from "../../assets/images/briefcase-icon.svg";
import list_checks_icon from "../../assets/images/list-checks-icon.svg";
import check_icon from "../../assets/images/check-icon.svg";
import white_blue_bg_frame from "../../assets/images/white-blue-bg-frame.svg";
import light_blue_bg_frame from "../../assets/images/light-blue-bg-frame.svg";
import blue_bg_frame from "../../assets/images/blue-bg-frame.svg";
import { jobStatusCount } from "../../store/action/dashboardAction";
import { Tokens } from '../../constants/index'
import { getAvatarName } from '../../shared/sharedMethod';

const Widget = (props) => {
  const { formattedMessage } = props;
  const [greeting, setGreeting] = useState('');
  const [openJobs, setOpenJobs] = useState(0);
  const [completedJobs, setCompletedJobs] = useState(0);
  const [undertakenJobs, setUndertakenJobs] = useState(0);
  const firstName = localStorage.getItem(Tokens.FIRST_NAME);
  const dispatch = useDispatch(); 

  useEffect(() => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      setGreeting('Good Morning');
    } else if (currentHour < 18) {
      setGreeting('Good Afternoon');
    } else {
      setGreeting('Good Evening');
    }
    
    const fetchJobCounts = async () => {
      const result = await dispatch(jobStatusCount());
      if (result && !result.error) {
        const jobCounts = result; 
        setOpenJobs(jobCounts[2]?.count || 0); 
        setCompletedJobs(jobCounts[1]?.count || 0); 
        setUndertakenJobs(jobCounts[0]?.count || 0);
      }
    };

    fetchJobCounts();

  }, [dispatch]);

  return (
    <div className="dashboard-widget">
      <div className="card no-border">
        <div className="greeting">
          <h1 className="greeting-time">{greeting},&nbsp;</h1>
          <h1 className="greeting-name">{ firstName} 👋</h1>
        </div>
        <p className="message">Keep track of your tasks and job status with ease.</p>
      </div>

      <div className="card widget-card-container">
        <div className="card widget-card"
          style={{
            backgroundImage: `url(${white_blue_bg_frame})`,
          }}>
          <div className="card-content">
            <div className="left-content">
              <h3 className="widget-title">
                {formattedMessage(
                  "dashboard.count.open-jobs"
                )}
              </h3>
              <h1 className="widget-count">{openJobs}</h1>
            </div>
            <div className="right-content">
              <img src={briefcase_icon} alt="Icon" className="widget-icon" />
            </div>
          </div>
        </div>

        <div className="separator" />

        <div className="card widget-card"
          style={{
            backgroundImage: `url(${light_blue_bg_frame})`,
          }}>
          <div className="card-content">
            <div className="left-content">
              <h3 className="widget-title">
                {formattedMessage(
                  "dashboard.count.taken-jobs"
                )}
              </h3>
              <h1 className="widget-count">{undertakenJobs}</h1>
            </div>
            <div className="right-content">
              <img src={list_checks_icon} alt="Icon" className="widget-icon" />
            </div>
          </div>
        </div>

        <div className="separator" />

        <div className="card widget-card"
          style={{
            backgroundImage: `url(${blue_bg_frame})`,
          }}>
          <div className="card-content">
            <div className="left-content">
              <h3 className="widget-title">
                {formattedMessage(
                  "dashboard.count.completed-jobs"
                )}
              </h3>
              <h1 className="widget-count">{completedJobs}</h1>
            </div>
            <div className="right-content">
              <img src={check_icon} alt="Icon" className="widget-icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Widget;
