import apiConfig from '../../config/apiConfig';
import {authActionType, Tokens, toastType, apiBaseURL} from '../../constants';
import {addToast} from './toastAction';
import {getFormattedMessage} from '../../shared/sharedMethod';

export const loginAction = (user, navigate, setLoading) => async (dispatch) => {
    try {
        const response = await apiConfig.post('identity-service/auth/login', user);
        localStorage.setItem(Tokens.VENDOR, response.data.accessToken);
        localStorage.setItem(Tokens.REFRESH, response.data.refreshToken);
        localStorage.setItem(Tokens.EMAIL, user.get('username'));
        dispatch({ type: authActionType.LOGIN_USER, payload: response.data });
        dispatch(addToast({text: getFormattedMessage('login.success.message')}));
        await dispatch(technicianDetailsAction());
        navigate('/app/dashboard');
    } catch (error) {
        setLoading(false);
        const errorMessage = error.response?.data?.errors?.[0] || "An error occurred. Please try again.";
        return { error: errorMessage };
    }
};

export const refreshTokenAction = () => async (dispatch) => {
    const refreshToken = localStorage.getItem(Tokens.REFRESH);

    if (!refreshToken) {
        return;
    }

    try {
        const response = await apiConfig.post('identity-service/auth/refresh-token', {
            token: refreshToken,
        });

        localStorage.setItem(Tokens.VENDOR, response.data.accessToken);
        dispatch({ type: authActionType.REFRESH_TOKEN, payload: response.data });
    } catch (error) {
        console.error('Failed to refresh token:', error);
    }
};

export const technicianDetailsAction = () => async () => {
    try {
        const technicianEmail = localStorage.getItem(Tokens.EMAIL);
        const res = await apiConfig.get(apiBaseURL.TECHNICIAN_ID + technicianEmail);
        localStorage.setItem(Tokens.USER_ID, res.data.id);
        const technicianId = localStorage.getItem(Tokens.USER_ID);
        const response = await apiConfig.get(apiBaseURL.TECHNICIAN_PROFILE + technicianId);
        localStorage.setItem(Tokens.FIRST_NAME, response.data.firstName);
        localStorage.setItem(Tokens.LAST_NAME, response.data.lastName);
        localStorage.setItem(Tokens.TECHNICIAN_ID, response.data.technicianId);
    } catch (error) {
        setLoading(false);
        const errorMessage = error.response?.data?.errors?.[0] || "An error occurred. Please try again.";
        return { error: errorMessage };
    }
};

export const logoutAction = (token, navigate) => async (dispatch) => {
    localStorage.removeItem(Tokens.VENDOR);
    localStorage.removeItem(Tokens.EMAIL);
    localStorage.removeItem(Tokens.TECHNICIAN_ID);
    localStorage.removeItem(Tokens.FIRST_NAME);
    localStorage.removeItem(Tokens.LAST_NAME);
    localStorage.removeItem('loginUserArray');
    navigate('/login');
};

export const forgotPassword = (email, setLoading) => async (dispatch) => {
    try {
        const response = await apiConfig.post(`${apiBaseURL.ADMIN_FORGOT_PASSWORD}/${email}`);
        dispatch({ type: authActionType.ADMIN_FORGOT_PASSWORD, payload: response.data });
        return { success: response.data.message };
    } catch (error) {
        setLoading(false);
        const errorMessage = error.response?.data?.errors?.[0] || "An error occurred. Please try again.";
        return { error: errorMessage };
    }
};

export const resetPassword = (user, setLoading) => async (dispatch) => {
    try {
        const response = await apiConfig.post(apiBaseURL.ADMIN_RESET_PASSWORD, user);
        dispatch({ type: authActionType.ADMIN_RESET_PASSWORD, payload: response.data });
        return { success: response.data.message };
    } catch (error) {
        setLoading(false);
        const errorMessage = error.response?.data?.errors?.[0] || "An error occurred. Please try again.";
        return { error: errorMessage };
    }
};

