import React, { useState, forwardRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import back_icon from "../../../assets/images/arrow-left.svg";
import briefcase from "../../../assets/images/briefcase.svg";
import car_cog from "../../../assets/images/car-cog.svg";
import wrench from "../../../assets/images/wrench.svg";
import caret_circle_down from "../../../assets/images/caret-circle-down.svg";
import caret_circle_up from "../../../assets/images/caret-circle-up.svg";
// import fetchJob from '../../../store/action/availableJobAction'; 
import { jobOverviewAction } from '../../../store/action/jobOverviewAction';

const AvailableJobDetail = forwardRef(({ selectedJob, onClickClaim, onBackButtonClick }, ref) => {
    const [activeServiceIndex, setActiveServiceIndex] = useState(0);
    const [jobOverview, setJobOverview] = useState({});
    const dispatch = useDispatch();

    const handleToggleService = (index) => {
        setActiveServiceIndex((prevIndex) => (prevIndex === index ? null : index));
    };
    
    useEffect(() => {
        const fetchJobOverview = async () => {
          if (selectedJob) {
            const result = await dispatch(jobOverviewAction(selectedJob));
            if (result && !result.error) {
              setJobOverview(result);
            }
          }
        };
        fetchJobOverview();
    }, [dispatch, selectedJob]);
      
    return (
        <div className="job-detail-content" ref={ref}>
            <div className="job-detail-top-header">
                <button className="back-button" onClick={onBackButtonClick}>
                    <img src={back_icon} alt="Back Icon" className="back-icon" />
                    <h5>Back</h5>
                </button>
                <div className="job-date">
                    <h6 className="label">Deadline: {new Date(jobOverview.dueDate).toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric'
                    })}</h6>
                </div>
            </div>
            <div className="job-detail-box">
                <div className="job-detail-header">
                    <h4>Job Details</h4>
                    <img src={briefcase} alt="Job Details Icon" />
                </div>
                <div className="entity">
                    <h5 className="entity-label">Job Card No</h5>
                    <h4 className="entity-value">{jobOverview.jobCardNumber}</h4>
                </div>
                <div className="entity">
                    <h5 className="entity-label">Job Description</h5>
                    <h4 className="entity-value">{jobOverview.description}</h4>
                </div>
                <div className="entity">
                    <h5 className="entity-label">Estimated Duration</h5>
                    <h4 className="entity-value">{jobOverview.estimatedHours} hours</h4>
                </div>
                <div className="entity">
                    <h5 className="entity-label">Priority</h5>
                    <h4 className="entity-value">{jobOverview.priority}</h4>
                </div>
                <div className="entity">
                    <h5 className="entity-label">Created Date</h5>
                    <h4 className="entity-value">{new Date(jobOverview.jobCreated).toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric'
                    })}</h4>
                </div>
                <div className="entity">
                    <h5 className="entity-label">Notes</h5>
                    <h4 className="entity-value">{jobOverview.notes}</h4>
                </div>
            </div>
            <div className="job-detail-box">
                <div className="job-detail-header">
                    <h4>Services</h4>
                    <img src={car_cog} alt="Services Icon" />
                </div>
                {jobOverview.jobServices && jobOverview.jobServices.length > 0 ? (
                    jobOverview.jobServices.map((service, index) => (
                        <div key={index} className={`service ${activeServiceIndex === index ? 'active' : ''}`}>
                            <div className="service-header">
                                {/* Assuming service has a 'description' property */}
                                <h4 className="service-title">{service.serviceName}</h4>
                                <button className="service-toggle" onClick={() => handleToggleService(index)}>
                                    <img src={caret_circle_down} alt="down" style={{ display: activeServiceIndex === index ? 'none' : 'inline' }} />
                                    <img src={caret_circle_up} alt="up" style={{ display: activeServiceIndex === index ? 'inline' : 'none' }} />
                                </button>
                            </div>
                            {activeServiceIndex === index && (
                                <span className="service-text">Task 1</span>
                            )}
                        </div>
                    ))
                ) : (
                    <p>No services available for this job.</p>
                )}
            </div>
            <div className="job-detail-box">
                <div className="job-detail-header">
                    <h4>Products Required</h4>
                    <img src={wrench} alt="Products Icon" />
                </div> 
                {jobOverview && jobOverview.jobProductList && jobOverview.jobProductList.length > 0 ? (
                <ul className="products-list">
                    {jobOverview.jobProductList.map((product, index) => (
                    <li key={index}><h4>{product.productName}</h4></li>
                    ))}
                </ul>
                ) : (
                <p>No products available for this job.</p>
                )}
            </div>
            <div className="box-footer">
                <button className="button" onClick={() => onClickClaim(selectedJob)}>
                    <h4>Claim Job</h4>
                </button>
            </div>
        </div>
    );
});

export default AvailableJobDetail;