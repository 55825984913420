import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MasterLayout from "../MasterLayout";
import Widget from '../dashboard/Widget';
import arrow_left from "../../assets/images/arrow-left.svg";
import profile_avatar from "../../assets/images/profile-avatar.png";
import update_image from "../../assets/images/image.svg";
import { getFormattedMessage } from '../../shared/sharedMethod';
import { myProfile } from '../../store/action/ProfileAction';
import { connect } from "react-redux";
import { useDispatch } from 'react-redux';
import moment from "moment";
import TopProgressBar from "../../shared/components/loaders/TopProgressBar";
import Spinner from "../../shared/components/loaders/Spinner";
import TabTitle from '../../shared/tab-title/TabTitle';
import { placeholderText } from '../../shared/sharedMethod';

const Profile = (props) => {
  const { isLoading, myProfile, profileData } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    myProfile();
  }, [dispatch]);

  return (
    <MasterLayout>
      <TopProgressBar />
      <TabTitle title={placeholderText('profile.title')} />
      <Widget formattedMessage={getFormattedMessage} />
      {isLoading ? <Spinner /> : <>
        <div className="profile-settings-container">
          <div className="profile-header">
            <Link to="app/dashboard" className="back">
              <img src={arrow_left} alt="Profile Photo" />
              <h3>Profile Settings</h3>
            </Link>
          </div>
          <div className="profile-card">
            <div className="profile-avatar">
              <div className="profile-photo">
                <img src={profile_avatar} alt="Profile Photo" />
                <div className="profile-info">
                  <h4>{profileData.firstName + ' ' + profileData.lastName}</h4>
                  <h6>{profileData.technicianEmail}</h6>
                </div>
              </div>
              <div className="update-photo">
                <img src={update_image} alt="Profile Photo" />
                <h5>Update Photo</h5>
              </div>
            </div>
            <div className="profile-details">
              <div className="profile-item">
                <h5>Name</h5>
                <h4>{profileData.firstName + ' ' + profileData.lastName}</h4>
              </div>
              <div className="profile-item">
                <h5>Date of Birth</h5>
                <h4>{moment(profileData.dateOfBirth).format("DD-MM-YYYY")}</h4>
              </div>
              <div className="profile-item">
                <h5>Phone Number</h5>
                <h4>{profileData.contactNumber}</h4>
              </div>
              <div className="profile-item">
                <h5>Email</h5>
                <h4>{profileData.technicianEmail}</h4>
              </div>
            </div>
            <div className="edit-details-button">
              <Link to="/app/edit-profile">
                <button className="button">Edit Details</button>
              </Link>
            </div>
          </div>
        </div>
      </>
      }
    </MasterLayout>
  );
};

const mapStateToProps = (state) => {
  const { profileData, isLoading } = state;
  return { profileData, isLoading };
};

export default connect(mapStateToProps, { myProfile })(Profile);