import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as EmailValidator from "email-validator";
import { loginAction } from "../../store/action/authAction";
import TabTitle from "../../shared/tab-title/TabTitle";
import { Tokens } from "../../constants";
import { createBrowserHistory } from "history";
import login_icon from '../../assets/images/login-icon.svg';
import eye_closed from '../../assets/images/eye-closed.svg';
import error_email from '../../assets/images/error-email.svg';
import error_password from '../../assets/images/error-password.svg';
import AuthHeader from '../header/AuthHeader';
import white_logo from '../../assets/images/karstation-logo-white.svg';


import {
    getFormattedMessage,
    placeholderText,
} from "../../shared/sharedMethod";


const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const history = createBrowserHistory();
    const { frontSetting } = useSelector((state) => state);
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem(Tokens.VENDOR);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [errorIcon, setErrorIcon] = useState("");
    const [errorTitle, setErrorTitle] = useState("");
    const [error, setError] = useState("");
    const [showPopup, setShowPopup] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);

    const [loginInputs, setLoginInputs] = useState({
        email: "",
        password: "",
    });

    useEffect(() => {
        if (token) {
            history.push(window.location.pathname);
        }
    }, []);

    const [errors, setErrors] = useState({
        email: "",
        password: "",
    });

    const handleValidation = () => {
        let errorss = {};
        let isValid = false;
        if (!EmailValidator.validate(loginInputs["email"])) {
            if (!loginInputs["email"]) {
                errorss["email"] = getFormattedMessage(
                    "globally.input.email.validate.label"
                );
            } else {
                errorss["email"] = getFormattedMessage(
                    "globally.input.email.valid.validate.label"
                );
            }
        } else if (!loginInputs["password"]) {
            errorss["password"] = getFormattedMessage(
                "user.input.password.validate.label"
            );
        } else {
            isValid = true;
        }
        setErrors(errorss);
        setLoading(false);
        return isValid;
    };

    const prepareFormData = () => {
        const formData = new FormData();
        formData.append("username", loginInputs.email);
        formData.append("password", loginInputs.password);
        return formData;
    };

    const onLogin = async (e) => {
        e.preventDefault();
        const valid = handleValidation();
        if (valid) {
            setLoading(true);
            try {
                const response = await dispatch(
                    loginAction(prepareFormData(loginInputs), navigate, setLoading)
                );
                if (response && response.error) {
                    setErrorIcon(error_email);
                    setErrorTitle("Login Failed");
                    setError(response.error);
                    setShowPopup(true);
                } else {
                    if (rememberMe) {
                        localStorage.setItem('email', loginInputs.email);
                    } else {
                        sessionStorage.setItem('email', loginInputs.email);
                    }
                    setLoginInputs({ email: "", password: "" });
                }
            } catch (error) {
                setErrorIcon(error_password);
                setErrorTitle("Unexpected Error");
                setError("An unexpected error occurred. Please try again.");
                setShowPopup(true);
            } finally {
                setLoading(false);
            }
        }
    };

    const closePopup = () => {
        setShowPopup(false);
        setError("");
    };

    const handleChange = (e) => {
        e.persist();
        setLoginInputs((inputs) => ({
            ...inputs,
            [e.target.name]: e.target.value,
        }));
        setErrors("");
    };

    return (
        <>
            <div className="container">
                <TabTitle
                    title={placeholderText("login-form.login-btn.label")}
                />
                <AuthHeader isLoginPage={true} />
                <div className="right-side">
                    <img
                        src={white_logo}
                        alt="KarStation"
                        className="logo hide-desktop"
                    />
                    <div className="form-container">
                        <img
                            src={login_icon}
                            alt="KarStation"
                            className="login-icon"
                        />
                        <h1>
                            {getFormattedMessage("login-form.title")}
                        </h1>
                        <form>
                            <div className="mb-4">
                                <div className="d-flex justify-content-between">
                                    <label className="form-label">
                                        {getFormattedMessage(
                                            "globally.input.email.label"
                                        )}{" "}
                                    </label>
                                </div>
                                <input
                                    placeholder={placeholderText(
                                        "globally.input.email.placeholder.label"
                                    )}
                                    required
                                    value={loginInputs.email}
                                    type="email"
                                    name="email"
                                    autoComplete="off"
                                    onChange={(e) => handleChange(e)}
                                />
                                <span className="text-danger d-block fw-400 fs-12 mt-2 text-start">
                                    {errors["email"] ? errors["email"] : null}
                                </span>
                            </div>

                            <div className="mb-3">
                                <div className="d-flex justify-content-between mt-n5">
                                    <div className="d-flex justify-content-between w-100">
                                        <label className="form-label">
                                            {getFormattedMessage(
                                                "user.input.password.label"
                                            )}
                                        </label>
                                    </div>
                                </div>
                                <div className="password-input-container">
                                    <input
                                        type={passwordVisible ? "text" : "password"}
                                        name="password"
                                        placeholder={placeholderText(
                                            "user.input.password.placeholder.label"
                                        )}
                                        autoComplete="off"
                                        required
                                        value={loginInputs.password}
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <img
                                        src={passwordVisible ? eye_closed : eye_closed}
                                        alt="Toggle password visibility"
                                        className="toggle-password-icon"
                                        onClick={() => setPasswordVisible(!passwordVisible)}
                                    />
                                </div>
                                <span className="text-danger d-block fw-400 fs-small mt-2 text-start">
                                    {errors["password"]
                                        ? errors["password"]
                                        : null}
                                </span>
                            </div>
                            <div className="mb-sm-7 mb-8">
                                <div className="d-flex justify-content-between mt-n5 align-items-center">
                                    <label className="custom-checkbox remember-me-label mb-0" htmlFor="rememberMe">
                                        <input
                                            type="checkbox"
                                            id="rememberMe"
                                            name="rememberMe"
                                            checked={rememberMe}
                                            onChange={(e) => setRememberMe(e.target.checked)}
                                        />
                                        <span className="checkmark"></span>
                                        {getFormattedMessage("login-form.remember-me.label")}
                                    </label>
                                    <Link
                                        to="/forgot-password"
                                        className="forgot-password-link-info text-decoration-none"
                                    >
                                        {getFormattedMessage("login-form.forgot-password.label")}
                                    </Link>
                                </div>
                            </div>
                            <div className="text-center">
                                <button
                                    type="submit"
                                    onClick={(e) => onLogin(e)}
                                >
                                    {loading ? (
                                        <span className="d-block">
                                            {getFormattedMessage(
                                                "globally.loading.label"
                                            )}
                                        </span>
                                    ) : (
                                        <span>
                                            {getFormattedMessage(
                                                "login-form.login-btn.label"
                                            )}
                                        </span>
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {showPopup && (
                <>
                    <div className="overlay"></div>
                    <div className="error-popup">
                        <div className="error-popup-content">
                            <span className="close-icon" onClick={closePopup}></span>
                            <img src={errorIcon} alt="error" className="error-icon" />
                            <h2>{errorTitle}</h2>
                            <p>{error}</p>
                            <button onClick={closePopup}>Retry</button>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default Login;
