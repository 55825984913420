import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MasterLayout from "../MasterLayout";
import Widget from '../dashboard/Widget';
import arrow_left from "../../assets/images/arrow-left.svg";
import profile_avatar from "../../assets/images/profile-avatar.png";
import update_image from "../../assets/images/image.svg";
import { getFormattedMessage } from '../../shared/sharedMethod';
import { myProfile } from '../../store/action/ProfileAction'; 
import { connect } from "react-redux";
import { useDispatch } from 'react-redux';

const ProfileEdit = (props) => {
  const {myProfile, profileData} = props;
  const dispatch = useDispatch(); 

  useEffect(() => {
    myProfile();
  }, [dispatch]);
 
  return (
    <MasterLayout>
      <Widget formattedMessage={getFormattedMessage} />
      <div className="profile-edit-container">
        <div className="profile-header">
          <Link to="app/dashboard" className="back">
            <img src={arrow_left} alt="Profile Photo" />
            <h3>Profile Settings</h3>
          </Link>
        </div>
        <div className="profile-card">
          <div className="profile-edit-avatar">
            <div className="profile-photo-edit">
              <img src={profile_avatar} alt="Profile Photo" />
              <div className="profile-info-edit">
                <h4>{profileData.firstName + ' ' + profileData.lastName}</h4>
                <p>{profileData.technicianEmail}</p>
              </div>
            </div>
            <div className="edit-update-photo">
              <a>
                <img src={update_image} alt="Profile Photo" />
                Update Photo
              </a>
            </div>
          </div>
          <div className="profile-edit-details">
            <div className="profile-edit-item">
              <label>First Name</label>
              <input
                type="text"
                id="first-name"
                name="first-name"
                value={profileData.firstName}
              ></input>
            </div>
            <div className="profile-edit-item">
              <label>Last Name</label>
              <input
                type="text"
                id="last-name"
                name="last-name"
                value={profileData.lastName}
              ></input>
            </div>
            <div className="profile-edit-item">
              <label>Date of Birth</label>
              <input
                type="text"
                id="name"
                name="date"
                value={profileData.dateOfBirth}
              ></input>
            </div>
            <div className="profile-edit-item">
              <label>Phone Number</label>
              <input
                type="phonenumber"
                id="phone"
                name="phone-number"
                value={profileData.contactNumber}
              ></input>
            </div>
            <div className="profile-edit-item">
              <label>Email</label>
              <input
                type="email"
                id="name"
                name="date"
                value={profileData.technicianEmail}
              ></input>
            </div>
          </div>
          <button className="profile-edit-button">Save Changes</button>
          <button className="profile-cancel-button">Cancel</button>
        </div>
      </div>
    </MasterLayout>
  );
};

const mapStateToProps = (state) => {
  const { profileData } = state;
  return { profileData };
};

export default connect(mapStateToProps, { myProfile })(ProfileEdit);