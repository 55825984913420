import {jobsActionType} from "../../constants";

export default (state = [], action) => {
    switch (action.type) {
        case jobsActionType.FETCH_AVAILABLE_JOBS:
            return action.payload;
        case jobsActionType.VIEW_JOB:
            return action.payload;
        case jobsActionType.CLAIM_JOB:
            return action.payload;
        default:
            return state;
    }
};
