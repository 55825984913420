import apiConfig from '../../config/apiConfig';
import {apiBaseURL, jobStatusCountActionType} from '../../constants';
import {setLoading} from "./loadingAction";

export const jobStatusCount = (dispatch) => async () => {
    try {
        const response = await apiConfig.get(apiBaseURL.JOB_STATUS_COUNT);
        //dispatch({ type: jobStatusCountActionType.JOB_STATUS_COUNT, payload: response.data });
        return response.data;
    } catch (error) {
        setLoading(false);
        const errorMessage = error.response?.data?.errors?.[0] || "An error occurred. Please try again.";
        return { error: errorMessage };
    }
};
