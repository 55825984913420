import React, { useState, forwardRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import back_icon from "../../../assets/images/arrow-left.svg";
import briefcase from "../../../assets/images/briefcase.svg";
import caret_up from "../../../assets/images/caret-up.svg";
import car_cog from "../../../assets/images/car-cog.svg";
import wrench from "../../../assets/images/wrench.svg";
import caret_circle_down from "../../../assets/images/caret-circle-down.svg";
import caret_circle_up from "../../../assets/images/caret-circle-up.svg";
import pencil_simple_line from "../../../assets/images/pencil-simple-line.svg";
import clock_counter_clockwise from "../../../assets/images/clock-counter-clockwise.svg";
import { jobOverviewAction } from '../../../store/action/jobOverviewAction';
import { fetchJob } from '../../../store/action/availableJobAction';
import { useDispatch } from 'react-redux';
import { Tokens } from '../../../constants/index'
import { connect } from "react-redux";
import moment from "moment";

const PendingJobDetail = forwardRef(({ selectedJob, onBackButtonClick, updateStatusData, getChecklistByService }, ref) => {
  const navigate = useNavigate();
  const [isJobDetailCollapsed, setIsJobDetailCollapsed] = useState(false);
  const [activeServiceIndex, setActiveServiceIndex] = useState(0);
  const [jobStatus, setJobStatus] = useState('Todo');
  const [jobOverview, setJobOverview] = useState({});
  const [technicianJobId, setTechnicianJobId] = useState("");
  const [technicianJobStatus, setTechnicianJobStatus] = useState("");
  const [checklists, setChecklists] = useState({});
  const dispatch = useDispatch();

  const handleCollapseJobDetail = () => {
    setIsJobDetailCollapsed((prevCollapsed) => !prevCollapsed);
  };

  const handleToggleService = (index) => {
    setActiveServiceIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const [jobValue, setjobValue] = useState({
      technicianJobId: '',
      estimatedHours: '',
      taskStatus: '',
      suggestFeedBack: ''
  });

  const [errors, setErrors] = useState({
      technicianJobId: '',
      estimatedHours: '',
      taskStatus: '',
      suggestFeedBack: ''
  });

  const prepareFormData = (data) => {
    return {
        technicianJobId: technicianJobId,
        estimatedHours: jobStatus === 'Completed' ? data.estimatedHours : null,
        taskStatus: jobStatus,
        suggestFeedBack: jobStatus === 'SuggestFeedBack' ? data.suggestFeedBack : null
    };
  };    

  const handleChange = (e) => {
      e.persist();
      setjobValue(inputs => ({...inputs, [e.target.name]: e.target.value}));
      setErrors('');
  };

  const handleValidation = () => {
    const errors = {};
    let isValid = true;
  
    if (jobStatus === 'SuggestFeedBack' && !jobValue.suggestFeedBack) {
      isValid = false;
      errors.suggestFeedBack = "Please provide feedback. ";
    }
  
    if (jobStatus === 'Completed' && !jobValue.estimatedHours) {
      isValid = false;
      errors.estimatedHours = "Please provide the actual estimate.";
    }
  
    setErrors(errors);
    return isValid;
  };
  
  const onSubmit = (e) => {
    e.preventDefault();
    const Valid = handleValidation();
    if (Valid) {
      updateStatusData(prepareFormData(jobValue), navigate);
      onBackButtonClick();
    }
  };

  useEffect(() => {
    const fetchJobOverview = async () => {
      if (selectedJob) {
        const result = await dispatch(jobOverviewAction(selectedJob));
        if (result && !result.error) {
          const technicianId = localStorage.getItem(Tokens.TECHNICIAN_ID);
            const filteredTechnicianJobs = result.technicianJobs.filter(
              (techJob) => techJob.technicianId === technicianId
          );
          setJobOverview({
            ...result,
            technicianJobs: filteredTechnicianJobs,
          });
          if (result.technicianJobs && result.technicianJobs.length > 0) {
            setTechnicianJobId(result.technicianJobs[0].id);
            setTechnicianJobStatus(result.technicianJobs[0].jobStatus);
          }
          if (result.jobServices && result.jobServices.length > 0) {
            const firstServiceId = result.jobServices[0].serviceId;
            setActiveServiceIndex(0);

            // Fetch checklist for the first service
           /* const checklistResult = await getChecklistByService(firstServiceId);
            console.log(checklistResult)
            if (checklistResult && !checklistResult.error) {
              setChecklists((prev) => ({
                ...prev,
                [firstServiceId]: checklistResult,
              }));
              console.log(checklistResult);
            } else {
              console.error(checklistResult.error || "Failed to fetch checklist.");
            }*/
          }
        }
      }
    };

    fetchJobOverview();
  }, [dispatch, selectedJob]);

  const fetchChecklist = async (serviceId) => {
    if (!checklists[serviceId]) {
      const checklistResult = await getChecklistByService(serviceId);
      if (checklistResult && !checklistResult.error) {
        setChecklists((prev) => ({
          ...prev,
          [serviceId]: checklistResult,
        }));
      } else {
        console.error(checklistResult.error || "Failed to fetch checklist.");
      }
    }
  };

  return (
    <div className="job-detail-content" ref={ref}>
      <div className="job-detail-top-header">
        <button className="back-button" onClick={onBackButtonClick}>
          <img src={back_icon} alt="Back Icon" className="back-icon" />
          <h5>Back</h5>
        </button>
        <div className={`job-status ${
            technicianJobStatus === 'TODO' 
                ? 'todo' 
                : technicianJobStatus === 'INPROGRESS' 
                ? 'in-progress' 
                : technicianJobStatus === 'SUGGESTEDFEEDBACK' 
                ? 'suggest-feedback' 
                : ''
            }`}>
          <h6>
              {technicianJobStatus === 'TODO' 
                  ? 'TO DO' 
                  : technicianJobStatus === 'INPROGRESS' 
                  ? 'In Progress' 
                  : technicianJobStatus === 'SUGGESTEDFEEDBACK' 
                  ? 'Suggest Feedback' 
                  : 'Unknown Status'}
          </h6>
        </div>
      </div>
      <div className="job-detail-box">
        <div className="my-job-detail-header">
          <div className="job-detail-header">
            <h4>Job Details</h4>
            <img src={briefcase} alt="Job Details Icon" />
          </div>
          <div className="collapse-icon" onClick={handleCollapseJobDetail}>
            <img src={caret_up} alt="Collapse Icon" className={isJobDetailCollapsed ? 'collapsed' : ''} />
          </div>
        </div>
        {!isJobDetailCollapsed && (
          <>
            <div className="entity">
              <h5 className="entity-label">Job Card No</h5>
              <h4 className="entity-value">{jobOverview.jobCardNumber}</h4>
            </div>
            <div className="entity">
              <h5 className="entity-label">Job Description</h5>
              <h4 className="entity-value">{jobOverview.description}</h4>
            </div>
            <div className="entity">
              <h5 className="entity-label">Estimated Duration</h5>
              <h4 className="entity-value">{jobOverview.estimatedHours} Hours</h4>
            </div>
            <div className="entity">
              <h5 className="entity-label">Priority</h5>
              <h4 className="entity-value">{jobOverview.priority}</h4>
            </div>
            <div className="entity">
              <h5 className="entity-label">Created Date</h5>
              <h4 className="entity-value">
                { moment(jobOverview.jobCreated).format("DD-MM-YYYY") }
              </h4>
            </div>
            <div className="entity">
              <h5 className="entity-label">Notes</h5>
              <h4 className="entity-value">{jobOverview.notes}</h4>
            </div>
          </>
        )}
      </div>
      <div className="job-detail-box">
        <div className="job-detail-header">
          <h4>Services</h4>
          <img src={car_cog} alt="Services Icon" />
        </div>
        {jobOverview.jobServices && jobOverview.jobServices.length > 0 ? (
            jobOverview.jobServices.map((service, index) => (
                <div key={index} className={`service ${activeServiceIndex === index ? 'active' : ''}`}>
                    <div className="service-header">
                        <h4 className="service-title">{service.serviceName}</h4>
                        <button className="service-toggle" onClick={() => handleToggleService(index)}>
                            <img src={caret_circle_down} alt="down" style={{ display: activeServiceIndex === index ? 'none' : 'inline' }} />
                            <img src={caret_circle_up} alt="up" style={{ display: activeServiceIndex === index ? 'inline' : 'none' }} />
                        </button>
                    </div>
                    {activeServiceIndex === index && (
                      <div className="service-checklist">
                        {checklists[service.serviceId] && checklists[service.serviceId].length > 0 ? (
                          checklists[service.serviceId].map((item, idx) => (
                            <div key={idx} className="checklist-item">
                              <input type="checkbox" className="service-checkbox" />
                              <span className="service-label">{item.checklistName}</span>
                            </div>
                          ))
                        ) : (
                          <p>No checklist items for this service.</p>
                        )}
                      </div>
                    )}
                </div>
            ))
        ) : (
            <p>No services available for this job.</p>
        )}
      </div>
      <div className="job-detail-box">
        <div className="job-detail-header">
          <h4>Products Required</h4>
          <img src={wrench} alt="Products Icon" />
        </div>
        {jobOverview && jobOverview.jobProductList && jobOverview.jobProductList.length > 0 ? (
          <ul className="products-list">
            {jobOverview.jobProductList.map((product, index) => (
              <li key={index}><h4>{product.productName}</h4></li>
            ))}
          </ul>
        ) : (
          <p>No products available for this job.</p>
        )}
      </div>
      <div className="job-detail-box">
        <div className="job-detail-header">
          <h4>Update Job Status</h4>
          <img src={pencil_simple_line} alt="Update Job Status Icon" />
        </div>
        <div className="job-status-update">
          <div className='job-status-header'>
            <h4>Current Job Status</h4>
            <img src={clock_counter_clockwise} alt="Current Job Status Icon" />
          </div>
          <div className="job-status-options">
            <div className="job-status-option">
              <input
                type="radio"
                name="job-status"
                id="todo"
                value="todo"
                checked={jobStatus === 'Todo'}
                onChange={() => setJobStatus('Todo')}
              />
              <label htmlFor="todo">To Do</label>
            </div>
            <div className="job-status-option">
              <input
                type="radio"
                name="job-status"
                id="in-progress"
                value="in-progress"
                checked={jobStatus === 'inprogress'}
                onChange={() => setJobStatus('inprogress')}
              />
              <label htmlFor="in-progress">In Progress</label>
            </div>
            <div className="job-status-option">
              <input
                type="radio"
                name="job-status"
                id="suggest-feedback"
                value="suggest-feedback"
                checked={jobStatus === 'SuggestFeedBack'}
                onChange={() => setJobStatus('SuggestFeedBack')}
              />
              <label htmlFor="suggest-feedback">Suggest Feedback</label>
            </div>
            {jobStatus === 'SuggestFeedBack' && (
              <div className="job-status-feedback">
                <textarea 
                  name="suggestFeedBack"
                  placeholder="Enter your feedback"
                  onChange={(e) => handleChange(e)}
                />
                <span className="text-danger d-block fw-400 fs-12 mt-2 text-start">
                    {errors["suggestFeedBack"] ? errors["suggestFeedBack"] : null}
                </span>
              </div>
            )}
            <div className="job-status-option">
              <input
                type="radio"
                name="job-status"
                id="complete"
                value="complete"
                checked={jobStatus === 'Completed'}
                onChange={() => setJobStatus('Completed')}
              />
              <label htmlFor="complete">Complete</label>
            </div>
            {jobStatus === 'Completed' && (
              <div className="job-status-feedback">
                <div className="job-status-feedback-box">
                  <h4 className="job-status-feedback-label">Actual Effort</h4>
                  <input 
                    type="number" 
                    name="estimatedHours"
                    min="0" 
                    onChange={(e) => handleChange(e)}
                  />
                  <span className="text-danger d-block fw-400 fs-12 mt-2 text-start">
                      {errors["estimatedHours"] ? errors["estimatedHours"] : null}
                  </span>
                </div>
                <div className="job-status-feedback-box">
                  <h4 className="job-status-feedback-label">Feedback</h4>
                  <textarea 
                    name="feedback"
                    placeholder="Enter your feedback" 
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <button className="button" onClick={(e) => onSubmit(e)}>
          <h4>Submit</h4>
        </button>
      </div>
    </div>
  );
});

export default PendingJobDetail;