import {jobsActionType} from "../../constants";

export default (state = [], action) => {
    switch (action.type) {
        case jobsActionType.MY_JOBS:
            return action.payload;
        case jobsActionType.UPDATE_JOB_STATUS:
            return action.payload;
        case jobsActionType.CHECKLIST_BY_SERVICE:
            return action.payload;
        default:
            return state;
    }
};
