import React, { useState, useEffect } from 'react';
import AvailableJobs from '../jobs/available-jobs/AvailableJobs';
import Myjobs from '../jobs/my-jobs/MyJobs';
import { useDispatch } from 'react-redux';
import { availableOpenJobs, claimJob } from '../../store/action/availableJobAction'; 
import { myActiveJobs } from '../../store/action/myJobsAction';
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

const Jobs = (props) => {
  const {availableOpenJobs, myActiveJobs,  isLoading, claimJob, openJobs, totalOpenJobsRecord, totalMyJobsRecord} = props;
  const [activeTab, setActiveTab] = useState(0);
  const [jobList, setJobList] = useState(openJobs || []);
  const dispatch = useDispatch(); 
  const navigate = useNavigate();

  const handleClaimJob = (jobId) => {
    claimJob(jobId, navigate)
      .then(() => {
        setJobList((prevJobs) => {
          availableOpenJobs();
          if (Array.isArray(prevJobs)) {
            return prevJobs.filter(job => job.jobId !== jobId);
          }
          return prevJobs;
        });
      })
      .catch(error => {
        console.error('Error claiming job:', error);
      });
  };

  useEffect(() => {
    availableOpenJobs();
    myActiveJobs();
  }, [dispatch, availableOpenJobs, myActiveJobs]);

  useEffect(() => {
    setJobList(Array.isArray(openJobs) ? openJobs : []);
  }, [openJobs]);
  

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="job-container">
      <div className="job-tab-header">
        <div className={`tab-item ${activeTab === 0 ? 'active' : ''}`} onClick={() => handleTabClick(0)}>
          <h4>Available Jobs</h4>
          <h4 className="tab-count">{String(totalOpenJobsRecord).padStart(2, '0')}</h4>
        </div>
        <div className={`tab-item ${activeTab === 1 ? 'active' : ''}`} onClick={() => handleTabClick(1)}>
          <h4>My Jobs</h4>
          <h4 className="tab-count">{String(totalMyJobsRecord).padStart(2, '0')}</h4>
        </div>
      </div>
      <div className="content-separator" />
      { activeTab === 0 && (
        <AvailableJobs jobs={jobList} handleClaimJob={handleClaimJob} />
      )}
      {activeTab === 1 && (
        <Myjobs />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { openJobs, totalOpenJobsRecord, totalMyJobsRecord, isLoading } = state;
  return { openJobs, totalOpenJobsRecord, totalMyJobsRecord, isLoading };
};

export default connect(mapStateToProps, { availableOpenJobs, myActiveJobs, claimJob })(Jobs);
