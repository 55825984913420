import React, { useState } from "react";
import { Layout } from "antd";
import Sidebar from "./sidebar/Sidebar";
import MainHeader from "./header/MainHeader";
import { Tokens } from '../constants/index'

const { Sider, Content } = Layout;

const MasterLayout = (props) => {
  const { children } = props;
  const [collapsed, setCollapsed] = useState(false);
  const [isSiderVisible, setIsSiderVisible] = useState(true); 
  const firstName = localStorage.getItem(Tokens.FIRST_NAME);
  const lastName = localStorage.getItem(Tokens.LAST_NAME);

  const toggleSider = () => {
    setIsSiderVisible(!isSiderVisible);
    setCollapsed(collapsed);
  };

  return (
    <Layout className="h-screen">
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed} 
        width={320}
        className={`sider ${!isSiderVisible ? "sider-visible" : ""}`}
      >
        <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} toggleSider={toggleSider} />
      </Sider>
      <Layout>
        <MainHeader toggleSider={toggleSider} firstName={firstName} lastName={lastName} />
        <Content className="main-content">
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default MasterLayout;
