import React, { useState, useEffect, useCallback } from "react";
import { Layout, Badge } from "antd";
import logo_icon from "../../assets/images/logo-icon.svg";
import bell from "../../assets/images/bell.svg";
import avatar from "../../assets/images/avatar.png";
import drawer from '../../assets/images/drawer.svg';

const { Header } = Layout;

const MainHeader = (props) => {
  const { toggleSider, firstName, lastName } = props;
  const [day, setDay] = useState('');
  const [weekday, setWeekday] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [time, setTime] = useState('');
  const [period, setPeriod] = useState('');

  const getCurrentDateAndTime = useCallback(() => {
    const date = new Date();

    const day = date.getDate().toString().padStart(2, '0');
    const weekday = date.toLocaleString('en-us', { weekday: 'long' });
    const month = date.toLocaleString('en-us', { month: 'short' }).toUpperCase();
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const period = hours >= 12 ? 'pm' : 'am';

    hours = hours % 12 || 12;
    const formattedHours = hours.toString().padStart(2, '0');

    const time = `${formattedHours}:${minutes}`;

    setDay(day);
    setWeekday(weekday);
    setMonth(month);
    setYear(year);
    setTime(time);
    setPeriod(period);
  }, []);

  useEffect(() => {
    getCurrentDateAndTime();
    const interval = setInterval(getCurrentDateAndTime, 1000);
    return () => clearInterval(interval);
  }, [getCurrentDateAndTime]);

  return (
    <Header className="header-container">
      <div className="left-container">
        <img src={drawer} 
          alt="drawer" 
          onClick={toggleSider}
        />
        <div className="date-and-time-container">
          <img src={logo_icon} alt="logo icon" className="logo-icon" />
          <div className="date-container">
            <div className="date-sub-container">
              <span className="day">{day}</span>
              <span className="weekday">{weekday}</span>
            </div>
            <div className="date-sub-container">
              <span className="month">{month}</span>
              <span className="year">{year}</span>
            </div>
          </div>
          <div className="time-container">
            <span className="time">{time}</span>
            <span className="period">{period}</span>
          </div>
        </div>
      </div>
      <div className="profile-container">
        <div className="notification-icon">
          <Badge
            count={15}
            overflowCount={99}
            offset={[-2, 2]}
            className="notification-count"
          >
            <img src={bell} alt="notification icon" />
          </Badge>
        </div>
        <div className="profile-info-container">
          <span className="profile-name">
          {firstName + ' ' + lastName}
          </span>
          <span className="profile-role">Technician Admin</span>
        </div>
        <img src={avatar} alt="profile photo" className="profile-photo" />
      </div>
    </Header>
  );
};

export default MainHeader;
