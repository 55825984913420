import {jobStatusCountActionType} from "../../constants";

export default (state = {}, action) => {
    switch (action.type) {
        case jobStatusCountActionType.JOB_STATUS_COUNT:
            return action.payload;
        default:
            return state;
    }
};
