import React, { useEffect, useState } from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Toasts from "./shared/toast/Toasts";
import { IntlProvider } from "react-intl";
import { settingsKey, Tokens } from "./constants";
import Login from "./components/auth/Login";
import ResetPassword from "./components/auth/ResetPassword";
import ForgotPassword from "./components/auth/ForgotPassword";
import VendorApp from "./VendorApp";
import "./assets/css/style.css";
import { getFiles } from "./locales/index";
import ProfileEdit from "./components/profile/ProfileEdit";

function App() {
    const dispatch = useDispatch();
    const { updateLanguage } = useSelector((state) => state);
    const token = localStorage.getItem(Tokens.VENDOR);
    const updatedLanguage = localStorage.getItem(Tokens.UPDATED_LANGUAGE);
    const { selectedLanguage, language } = useSelector(
        (state) => state
    );
    const [allLocales, setAllLocales] = useState({});
    const [messages, setMessages] = useState({});
    const [userEditedMessage, setUserEditedMessage] = useState({});
    const updateLanguag =
        allLocales[updatedLanguage ? updatedLanguage : selectedLanguage];
    const [languageData, setLanguageData] = useState([]);

    const mapPermissionToRoute = (permission) => {
        const entity = permission.split('_')[1];
        return `/app/${entity}`;
    };

    const [mappedRoutes, setMappedRoutes] = useState([]);
    const [redirectTo, setRedirectTo] = useState('');

    const ignoreDefaulMessageError = (error) => {
        if (typeof error.message === "string" && error.message.includes("[@formatjs/intl Error MISSING_TRANSLATION]")) {
          return;
        }
    };
/*
    useEffect(() => {
        setMappedRoutes(config.map(mapPermissionToRoute));
    }, [config]);
*/
    useEffect(() => {
      /*  if (mappedRoutes && mappedRoutes.length > 0) {
            if (config.includes('manage_dashboard')) {
                setRedirectTo('/app/dashboard');
            } else {
                setRedirectTo(mappedRoutes[0]);;
            }
        } else {*/
            setRedirectTo('/app/dashboard');
       // }
    }, []);

    useEffect(() => {
        const getData = getFiles();
        setAllLocales(getData);
    }, [language, updateLanguage?.lang_json_array]);

    useEffect(() => {
        if (updateLanguage?.iso_code === updatedLanguage && languageData) {
            setUserEditedMessage(updateLanguage?.lang_json_array);
        }
    }, [language, languageData]);

    // updated language hendling
    useEffect(() => {
        if (Object.values(userEditedMessage).length !== 0) {
            setMessages(userEditedMessage);
        } else {
            if (updateLanguage?.iso_code === updatedLanguage) {
                const updateLanguages = updateLanguage?.lang_json_array;
                setMessages(updateLanguages);
            } else {
                if (
                    updateLanguag === undefined ||
                    updateLanguag === null ||
                    updateLanguag === ""
                ) {
                    const defaultUpdateLanguage = allLocales["en"];
                    setMessages(defaultUpdateLanguage);
                } else {
                    if (updateLanguag === undefined || updateLanguag === null) {
                        const defaultUpdateLanguage = allLocales["en"];
                        setMessages(defaultUpdateLanguage);
                    } else {
                        setMessages(updateLanguag);
                    }
                }
            }
        }
    }, [allLocales, updateLanguage?.lang_json_array]);

    return (
        <div className="d-flex flex-root">
        <IntlProvider
            locale={settingsKey.DEFAULT_LOCALE}
            messages={messages}
            onError={ignoreDefaulMessageError}
        >
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route
                        path="reset-password/:token/:email"
                        element={<ResetPassword />}
                    />
                    <Route
                        path="forgot-password"
                        element={<ForgotPassword />}
                    />
                    <Route
                        path="app/*"
                        element={<VendorApp />}
                    />
                    <Route path="app/edit-profile" element={<ProfileEdit />} />
                    <Route
                        path="/"
                        element={
                            <Navigate
                                replace
                                to={token ? redirectTo : "/login"}
                            />
                        }
                    />
                    <Route path="*" element={<Navigate replace to={"/"} />} />
                </Routes>
                <Toasts
                    language={
                        updatedLanguage ? updatedLanguage : selectedLanguage
                    }
                />
            </IntlProvider>
        </div>
    );
}

export default App;
